.footer {
    background-color: #69ff33a1; /* Light green background with some transparency */
    color: black;
    padding: 20px;
    text-align: center;
    margin-top: 40px;
    box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.1);
}

.footer-links {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    flex-wrap: wrap;
}

.footer-link {
    margin: 0 15px;
    text-decoration: none;
    color: black;
    font-weight: 600;
    transition: color 0.3s ease;
}

.footer-link:hover {
    color: #4dc727; /* Darker green for hover effect */
}

.footer-powered {
    font-size: 0.9em;
    color: black;
}

.footer-powered a {
    color: black;
    font-weight: bold;
    text-decoration: none;
}

.footer-powered a:hover {
    color: #4dc727; /* Darker green for hover effect */
}

/* Mobile responsiveness */
@media screen and (max-width: 768px) {
    .footer {
        padding: 15px;
        margin-top: 20px;
    }

    .footer-links {
        flex-direction: column;
        gap: 10px;
    }

    .footer-link {
        margin: 5px 0; /* Adjust margin for better spacing */
    }

    .footer-powered {
        font-size: 0.8em; /* Slightly smaller font size on mobile */
        margin-top: 15px;
    }
}
