/* Container holding sidebar and content */
.sidebar-container {
    display: flex;
}

/* Sidebar styles */
.sidebar {
    width: 250px;
    height: 100vh;
    background-color: #69ff33a1;
    color: white;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    padding-top: 20px;
    z-index: 1000;
    transition: width 0.3s ease;
}

.sidebar.open {
    width: 250px;
}

.sidebar:not(.open) {
    width: 80px;
}

/* Toggle button */
.toggle-btn {
    font-size: 35px;
    cursor: pointer;
    padding: 15px;
    color: black;
    text-align: right;
    font-weight: 900;
}

/* Menu items */
.menu {
    margin-top: 50px;
    flex-grow: 1;
}

.link {
    display: flex;
    align-items: center;
    padding: 15px 20px;
    text-decoration: none;
    color: black;
    font-weight: 600;
    transition: background-color 0.3s ease;
}

.link:hover {
    background-color: #4dc727;
}
.link_logout {
    display: flex;
    align-items: center;
    padding: 15px 20px;
    text-decoration: none;
    color: rgb(44, 1, 1);
    font-weight: 600;
    transition: background-color 0.3s ease;
}

.link_logout:hover {
    background-color: #f51010;
}

.active {
    background-color: #4dc727;
}

/* Icon styles */
.icon {
    font-size: 24px;
    margin-right: 15px;
}
.icon_logout {
    font-size: 25px;
    margin-right: 15px;
}

/* When sidebar is collapsed */
.sidebar:not(.open) .icon {
    margin-right: 0;
}

.sidebar:not(.open) .link_text {
    display: none;
    color: black;
}

/* Content area */
.content {
    margin-left: 250px;
    padding: 20px;
    width: calc(100% - 250px);
    transition: margin-left 0.3s ease;
}

.content.shifted {
    margin-left: 80px;
}

/* Responsive styles */
@media screen and (max-width: 768px) {
    .sidebar {
        width: 100%;
        height: auto;
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 1000;
        transition: height 0.3s ease, transform 0.3s ease;
        align-items: center;
        /* transform: translateY(100%); */
    }

    .sidebar.open {
        /* transform: translateY(100%); */
        /* width: 200px; */
    }

    .sidebar:not(.open) .icon {
        /* transform: translateY(100%); */
    }

    .content {
        margin-left: 50px;
        width: 100%;
    }

    .content.shifted {
        margin-left: 50px;
    }

    /* Make links vertical in mobile */
    .menu {
        flex-direction: column;
        align-items: flex-start;
    }

    .link {
        justify-content: flex-start;
        padding: 10px;
        font-size: 18px;
    }
    .link_logout {
        justify-content: flex-start;
        padding: 10px;
        font-size: 18px;
    }

    .toggle-btn {
        font-size: 28px;
        padding: 10px;
        position: absolute;
        right: 15px;
        top: 15px;
    }
}
